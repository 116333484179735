<template>
  <div>
    <v-sheet
      v-if="loading"
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table, selection"
      >
      </v-skeleton-loader>
    </v-sheet>

    <v-card v-else>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.active"
        :bg-color="snackbar.color"
      ></snack-view>
      <div>
        <v-card-title class="font-weight-bold">
          Liste des locaux
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Rechercher"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="emplacements"
          :search="search"
          :options="dataOptions"
          :items-per-page="15"
          sort-by="id"
          class="table-kitchen-sink"
        >
          <!-- product -->
          <template #[`item.num`]="{item}">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ emplacements.indexOf(item) + 1 }}</span>
              </div>
            </div>
          </template>

          <template #[`item.nom_local`]="{item}">
            <div class="align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
              </div>
            </div>
          </template>

          <template #[`item.typ_local`]="{item}">
            <div class="align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.local_type.name }}</span>

              </div>
            </div>
          </template>

          <template #[`item.nbr_decodeur`]="{item}">
            <div class="align-center">
              <div class="d-flex flex-column ">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.decoders.length }}</span>

              </div>
            </div>
          </template>

          <template #[`item.actions`]="{ item }">
            <div class="col-12 w-">
              <v-btn
                class="ml-1"
                outlined
                x-small
                color="#F06529"
                @click="goTolistDecodeur(item)"
              >
                <v-icon
                  x-small
                  class="me-1"
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
                Visualiser les décodeurs
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>

</template>

<script>
import { ref, onBeforeMount } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiEyeOutline,
  mdiCloseCircle,
} from '@mdi/js'
import data from '@/views/locaux/datatable'
import Http from '@/helpers/http'
import { useRouter } from '@core/utils'
import Vue from 'vue'
import moment from 'moment'
import SnackView from '@/views/SnackView'
// eslint-disable-next-line camelcase
import { is_category_flotte } from '@/helpers/constants/contrat'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
Vue.prototype.moment = moment
export default {
  components: {
    SnackView,
  },
  setup() {
    const loading = true
    const search = ref('')
    const emplacements = ref([])
    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
    })
    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]
    const orderNum = item => emplacements.value.indexOf(item) + 1
    const typeLocalBackup = ref(null)
    const selectedLocaux = ref([])
    const clients = ref([])
    // eslint-disable-next-line camelcase
    const t_contrat = ref([])
    const segment = ref([])

    const isContratFlotte = ref(is_category_flotte())
    return {
      icons: {
        mdiMagnify,
        mdiEyeOutline,
        mdiCloseCircle,
      },
      search,
      LocalNameRules,
      orderNum,
      clients,
      t_contrat,
      segment,
      headers: [
        { text: 'N°', value: 'num', divider: true,  align: 'center' },
        { text: !isContratFlotte.value ? 'Nom de l\'emplacement' : 'Nom du collaborateur', value: 'nom_local', divider: true,  align: 'center' },
        { text: !isContratFlotte.value ? 'Type d\'emplacement' : 'Type de collaborateur', value: 'typ_local', divider: true,  align: 'center' },
        { text: 'Nbre de décodeurs', value: 'nbr_decodeur', divider: true,  align: 'center' },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],
      dataOptions: {
        sortBy: ['id'],
      },
      emplacements,
      loading,
      editedIndex: -1,
      defaultItem: {},
      snackbar,
      dialogDelete: false,
      selectedLocaux,
      typeLocalBackup,
      isContratFlotte,
      selectedSegmentGlobal: null,
      selectedContactGlobal: null,
      selectedClientGlobal: null,
    }
  },
  mounted() {
    this.getLocalType()
    this.loadEmplacements()
  },
  methods: {
    initialize() {
      this.ListLocalFille = JSON.parse(JSON.stringify(data))
    },
    created() {
    },
    getLocalType() {
      Http.get('local-type', { errorHandle: false })
        .then(async response => {
          // eslint-disable-next-line no-multi-assign
          this.typeLocal = this.typeLocalBackup = await response.data
        })
    },

    loadEmplacements() {
      this.loading = true
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let customerId = storedData.selectedLine.id
      Http.post('get-all-local-type-customer', {customerId})
        .then(response => {
          this.loading = false
          this.emplacements = response.data
          console.log('emplacements')
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    },

    goTolistDecodeur(item) {
      this.locals = item
      localStorage.setItem("localIdFille", JSON.stringify({ locals: this.locals }))
      let storedData = JSON.parse(localStorage.getItem("clientFilleId"))
      let custoid = storedData.locals
      console.log("id local", custoid)
      Http.post('get-all-decoder-customer', {localId: this.locals.id})
        .then( response => {
          if (response.status === 200) {
            this.locals = response.data
            router.push({ name: 'list-decoderFille', params: { localId: this.locals.id, data: [] } })
          }
        })
        .catch(error => {
          console.error('Erreur :', error)
        })
    },

    clientChange() {
      alert(this.selectedClientGlobal)
    },

  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
